import '../styles/Banner.css';
import { useEffect, useState } from 'react';
import { Link as ScrollLink } from 'react-scroll';
import { useTranslation } from 'react-i18next';
import { ShootingStars } from "../components/ui/shooting-star";
import { StarsBackground } from "../components/ui/star-background";
import { GlobeDemo } from '../components/GlobeDemo';
import { FlipWords } from '../components/ui/flip-words';

function Banner() {
    const { t } = useTranslation();
    const words = ["Next", "React | Node", "React | Nest"];

    const [showGlobe, setShowGlobe] = useState(false);
    const [moveGlobe, setMoveGlobe] = useState(true); // Cache le globe à droite au début

    useEffect(() => {
        const globeTimer = setTimeout(() => {
            setShowGlobe(true); // Affiche le globe après 1s
            setTimeout(() => {
                setMoveGlobe(false); // Déclenche l'animation après un court délai
            }, 1000); // Délai minime pour que l'animation soit visible
        }, 600);

        return () => clearTimeout(globeTimer);
    }, []);

    return (
        <div className="banner">
            <div className="bannerColor">
                {!moveGlobe && (
                    <ShootingStars />
                )}
                <StarsBackground />
                <div className="bannerLastName">JOST</div>
            </div>
            <div className="bannerBlack relative">
                {/* <div class="circle-container">
                    <div class="sphere"></div>
                </div> */}
                {!moveGlobe && (
                    <ShootingStars />
                )}

                <StarsBackground />
                {showGlobe && (
                    <div
                        className={`globeDemo cursor-pointer absolute -bottom-72 -right-72 w-[700px] 
                        max-[991px]:-bottom-52 max-[991px]:-right-64 max-[991px]:w-[600px] 
                        max-[635px]:w-[400px] max-[635px]:-bottom-40 max-[635px]:-right-44
                        transition-transform duration-700 ease-out
                        ${moveGlobe ? "translate-x-[100%] opacity-0" : "translate-x-0 opacity-100"}`}
                    >
                        <GlobeDemo />
                    </div>
                )}
                <div className="bannerContent">
                    <div className="bannerFirstName">CHRISTOPHER</div>
                    <h1>{t('bannerTitleTranslate')}</h1>
                    <div className="bannerAbout">
                        <FlipWords words={words} />
                    </div>
                    <div className="bannerLinks">
                        <ScrollLink
                            href="contactLien"
                            to="contactLien"
                            className="bannerLink z-10"
                            spy={true}
                            smooth={true}
                            duration={1000}
                        >
                            <span className="material-symbols-rounded bannerContact">
                                mail
                            </span>
                        </ScrollLink>
                        <a
                            className="bannerLink githubLink z-10"
                            href="https://github.com/chrischris4"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <img
                                className="imgContact"
                                src="https://i.ibb.co/DVbScgL/icons8-github-128.png"
                                alt=""
                            />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Banner;
